export class Policy {
  constructor(
    public first_name: string,
    public last_name_one: string,
    public last_name_two: string,
    public email: string,
    public cellphone: string,
    public phone: string,

    public kilometers_package_id: string,
    public quote_id: string,
    public motor_number: string,
    public vin: string,
    public plates: string,
    public street1: string,
    public ext_number1: string,
    public int_number1: string,
    public zipcode1: string,
    public suburb1: string,
    public city1: string,
    public state1: string,
    public street2: string,
    public ext_number2: string,
    public int_number2: string,
    public zipcode2: string,
    public suburb2: string,
    public city2: string,
    public state2: string,
    public street3: string,
    public ext_number3: string,
    public int_number3: string,
    public zipcode3: string,
    public suburb3: string,
    public city3: string,
    public state3: string,
    public rfc: string,
    public razon_social: string,
    public payment_method: string,
    public store: string,
    public total_amount: string,
    public deviceIdHiddenFieldName: string,
    public token_id: string,
    public factura: boolean,
    public subscription: boolean,
    public promotional_code: string,
    public msi: string,
    public paytype: string,
    public free_kilometers: number
  ) { }
}
